<template>
  <div>
    <router-link class="come-back" to="/admin/bill_draft">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование Законопроетка' : 'Создание Законопроетка' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="creteBill" ref="creteBill">
      <div>
        <div class="content-sb">
          <p>Название<span>*</span></p>
          <input required placeholder="Введите название законопроекта" v-model="editData.name" type="text" name="name" class="input blue">
        </div>
        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Статус<span>*</span></p>
          <select  @change="changeStatusDraft" ref="changeStatusDraft" required class="input blue"  name="type">
            <option disabled>Выберите статус законопроекта</option>
            <option v-for="item in $store.getters.getStatusBillDraft"  :selected="editData.state === item.title" :key="`getStatusBillDraft${item.code}`" :value="item.code">{{ item.title }}</option>
          </select>
        </div>
        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required  type="date" v-model="editData.date" name="date" class="input blue">
        </div>
        <div class="content-sb">
          <p>Текст</p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="editData.text"></ckeditor>
        </div>
      </div>
      <div v-for="(item, i) in documents" :key="`documents${i}`">
          <div class="content-sb">
            <p>Приоритет</p>
            <input required v-model="documents[i].order" type="number"  class="input blue">
          </div>
          <div class="content-sb">
            <p>Название файла</p>
            <input required placeholder="Ввыедите название файла" v-model="documents[i].title" type="text"  class="input blue">
          </div>
          <div v-if="$route.query.type === 'edit' && item.version === 'oldFile'" class="content-sb">
            <p>Старый докумет</p>
            <a target="_blank" :href="`${$store.getters.getUrlApi}${item.url}`">ссылка</a>
          </div>
          <div v-else class="content-sb">
            <p>Документы проекта<span>*</span></p>
            <input required type="file" @change="addFile($event, i)" ref="documents"  class="input blue">
          </div>
          <div class="btn-red" @click="deleteDocumentsList(i)">Удалить</div>
      </div>
      <div class="btn-blue" @click="addDocumentsList">Добавить</div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/bill_draft" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createBillDraft',
  data () {
    return {
      editData: this.$route.query.type === 'edit' ? this.$store.getters.getDetailBillDraft : {
        name: '',
        text: '',
        date: '',
        state: ''
      },
      documents: []
    }
  },
  watch: {
    '$store.getters.getDetailBillDraft': {
      deep: true,
      handler (value) {
        const validDate = this.editData.date.split('T')
        this.editData.date = validDate[0]

        if (this.documents.length === 0) {
          value.fullDocument.map(item => {
            this.documents.push({
              id: item.id,
              title: item.name,
              file: null,
              url: item.url,
              order: item.order,
              version: 'oldFile'
            })
          })
        }
      }
    }
  },
  methods: {
    creteBill () {
      const formData = new FormData(this.$refs.creteBill)
      if (this.editData.text) {
        formData.append('text', this.editData.text)
      }
      this.documents.forEach((item, i) => {
        if (item.file) {
          formData.append(`documents[${i}][file]`, item.file)
        }
        if (this.$route.query.type === 'edit' && item.id) {
          formData.append(`documents[${i}][id]`, item.id)
        }
        formData.append(`documents[${i}][order]`, item.order)
        formData.append(`documents[${i}][title]`, item.title)
      })
      // formData.append('documents', this.form.documents)
      if (this.$route.query.type === 'edit') {
        this.editData.state = this.$refs.changeStatusDraft.value
        formData.append('state', this.editData.state)
        this.$store.dispatch('editBillDraft', { id: this.editData.id, data: formData })
      } else {
        this.$store.dispatch('AddBillDraft', formData)
      }
    },
    addDocumentsList () {
      this.documents.push({
        file: null,
        order: 0,
        version: 'newFile',
        title: ''
      })
    },
    deleteDocumentsList (i) {
      this.documents.splice(i, 1)
    },
    addFile (event, i) {
      this.documents[i].file = event.target.files[0]
    },
    changeStatusDraft (e) {
      this.editData.state = e.target.value
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailBillDraft === null) {
      this.$router.push('/admin/bill_draft')
    }
    this.$store.dispatch('setStatusBillDraft')
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
.btn-red, .btn-blue {
  margin-bottom: 2rem;
  display: inline-flex;
}
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
  a {
    color: #0b6cc6;
    height: fit-content;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
